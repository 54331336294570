export class AxiosRequestUtils {
  static formHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
  }

  static jsonHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }

  static jsonFormDataHeaders(): Record<string, string> {
    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };
  }

  static encodeUrl(url: string, data?: Record<string, string | number>): string {
    if (data != null) {
      url +=
        '?' +
        Object.keys(data)
          .map(
            (key) => key + '=' +
            encodeURIComponent(data[key])
              .replace('!', '%21')
              .replace(`'`, '%27')
              .replace('(', "%28")
              .replace(')', "%29")
              .replace('*', "%2A")
          )
          .join('&');
    }

    return url;
  }
}
