import { hookstate, useHookstate } from "@hookstate/core";
import { localstored } from "@hookstate/localstored";
import getSessionStoreEngine from "./SessionStoreEngine";
import { TransactionType, TransactionTypeId, TransactionTypeName } from "model/TransactionType";

export interface AppState {
  transactionTypes: TransactionType[];
  selectedTransactionType: TransactionType | null;
}

export const defAppState = () => {
  return {
    transactionTypes: [
      new TransactionType(TransactionTypeId.RXID, TransactionTypeName[TransactionTypeId.RXID]),
      new TransactionType(TransactionTypeId.RXID_PLUS, TransactionTypeName[TransactionTypeId.RXID_PLUS]),
      new TransactionType(TransactionTypeId.FAS, TransactionTypeName[TransactionTypeId.FAS])
    ],
    selectedTransactionType: null
  };
};

const state = hookstate<AppState>(
  defAppState(),
  localstored({
    key: "app-state",
    engine: getSessionStoreEngine(),
  })
);

export const useAppState = () => {
  return useHookstate(state);
};
