import { AxiosResponseUtils } from 'lib/axios/AxiosResponseUtils';
import { AxiosError } from 'axios/index';
import { getFileRepository } from 'repositories/FileRepository';
import { FileEntry } from 'model/FileEntry';
import { DataTableResponse } from 'model/datatable/DataTableResponse';

const fileRepository = getFileRepository();

export const fetchFiles = async (): Promise<DataTableResponse<FileEntry>> => {
  let response = new DataTableResponse<FileEntry>([], 0);

  try {
    const filesResponse = await fileRepository.files();
    response = filesResponse.data;
  } catch (error) {
    AxiosResponseUtils.error(error as AxiosError);
  }

  return response;
}
