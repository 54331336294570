import { Navigate, RouteProps } from 'react-router-dom';
import React from 'react';
import { Auth } from 'lib/auth/Auth';

export type ProtectedRouteProps = {
  element: JSX.Element;
} & RouteProps;

const ProtectedRouteElement = (props: ProtectedRouteProps) => {
  if (Auth.isAuthorized()) {
    return props.element;
  } else {
    return <Navigate to="/" />;
  }
};

export default ProtectedRouteElement;
