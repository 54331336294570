export interface User {
  username: string | null;
}

export class User implements User {
  username: string | null = null;

  constructor(username: string | null) {
    this.username = username;
  }
}
