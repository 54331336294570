import styles from 'assets/styles/AppTitle.module.css';

export interface AppTitleProps {
  title?: string;
}

const AppTitle = (props: AppTitleProps) => {
  return (
    <h1 className={styles.title}>{props.title ?? 'OPRX Batch Transaction Tool'}</h1>
  )
}

export default AppTitle;
