import { LoginInfo } from './LoginInfo';
import { AxiosError } from 'axios';
import { UserInfo } from './UserInfo';
import { isExpired } from 'react-jwt';
import { UserInfoStorage } from './UserInfoStorage';
import { getAuthRepository } from '../../repositories/AuthRepository';
import { User } from '../../model/User';
import { BsNotification } from '../utils/BsNotification';
import { AxiosResponseUtils } from '../axios/AxiosResponseUtils';

const authRepository = getAuthRepository();

export class Auth {
  static async login(info: LoginInfo): Promise<boolean> {
    if (info.username && info.password) {
      try {
        this.clearUserInfo();
        const response = await authRepository.login(info);

        if (response.status === 200) {
          const userInfo = new UserInfo();
          userInfo.user = new User(info.username);
          userInfo.token = response.data.token;
          this.setUserInfo(userInfo);
        }

        return response.status === 200;
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.response?.status === 401) {
          BsNotification.error('Wrong username or password');
        } else {
          AxiosResponseUtils.error(axiosError);
        }

        return false;
      }
    } else {
      return true;
    }
  }

  static async logout(): Promise<boolean> {
    try {
      this.clearUserInfo();
      return true;
    } catch (error) {
      AxiosResponseUtils.error(error as AxiosError);
      return false;
    }
  }

  static getUserInfo(): UserInfo {
    return UserInfoStorage.getUserInfo();
  }

  static setUserInfo(userInfo: UserInfo): void {
    UserInfoStorage.setUserInfo(userInfo);
  }

  static clearUserInfo(): void {
    UserInfoStorage.clearUserInfo();
  }

  static isAuthorized(): boolean {
    const userInfo = Auth.getUserInfo();
    const token = userInfo.token;
    const isTokenExpired = isExpired(token ?? '');
    return userInfo.user != null && token != null && !isTokenExpired
  }
}
