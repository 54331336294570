import { TimerOptions } from './TimerOptions';

export class Timer {
  private options = new TimerOptions();
  private timeout?: number;
  private cycle = 1;
  private active = false;

  constructor(options?: TimerOptions) {
    if (options) {
      this.options = { ...this.options, ...options };
    }

    if (this.options.autoStart) {
      this.start();
    }
  }

  start(): void {
    if (this.active) {
      return;
    }

    this.active = true;

    if (this.options.startWithTick) {
      this.tick();
    } else {
      this.tickTimeout();
    }
  }

  stop(): void {
    this.pause();
    this.cycle = 1;
  }

  pause(): void {
    this.active = false;
    clearTimeout(this.timeout);
  }

  resume(): void {
    if (this.active) {
      return;
    }
    this.active = true;
    this.tickTimeout();
  }

  restart(): void {
    this.stop();
    this.start();
  }

  private tick(): void {
    if (!this.active) {
      return;
    }

    if (this.options.tick) {
      this.options.tick.call(this, this.cycle);
    }

    if (this.options.cycles != null && this.cycle === this.options.cycles) {
      this.stop();
    } else {
      this.cycle++;
      this.tickTimeout();
    }
  }

  private tickTimeout(): void {
    // @ts-ignore
    this.timeout = setTimeout(() => {
      this.tick();
    }, this.options.interval);
  }
}
