import { type StoreEngine } from "@hookstate/localstored";

const instance = null;

export class SessionStoreEngine implements StoreEngine {
  getItem(key: string): Promise<string | null> | string {
    return sessionStorage.getItem(key) ?? "";
  }

  removeItem(key: string): Promise<void> | void {
    return sessionStorage.removeItem(key);
  }

  setItem(key: string, value: string): Promise<void> | void {
    return sessionStorage.setItem(key, value);
  }
}

const getSessionStoreEngine = () => {
  if (instance != null) {
    return instance;
  }
  return new SessionStoreEngine();
};

export default getSessionStoreEngine;
