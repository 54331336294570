import React, { useEffect, useState } from 'react';
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { Auth } from 'lib/auth/Auth';
import { useNavigate } from "react-router-dom"
import AppTitle from 'components/AppTitle';
import { Card } from 'react-bootstrap';
import styles from 'assets/styles/Login.module.css';
import Page from 'layouts/Page';
import CenteredContainer from 'components/CenteredContainer';
import CenteredCard from 'components/CenteredCard';
import { ElementState } from 'model/ElementState';
import Button from 'components/Button';
import { LoginInfo } from 'lib/auth/LoginInfo';
import PersonFillIcon from "./../assets/images/person-fill.svg";
import KeyFillIcon from "./../assets/images/key-fill.svg";

const Login = () => {
  const { register, handleSubmit, control } = useForm<LoginInfo>();
  const defState: ElementState = {
    disabled: true,
    loading: false
  };
  const [state, setState] = useState<ElementState>(defState);
  const navigate = useNavigate();

  const username = useWatch({
    control: control,
    name: 'username',
  });

  const password = useWatch({
    control: control,
    name: 'password',
  });

  useEffect(() => {
    if ((username && username.length > 0) && (password && password.length > 0)) {
      setState({ disabled: false });
    } else {
      setState({ disabled: true });
    }
  }, [username, password]);

  const submit = async (data: FieldValues) => {
    if (state.loading) {
      return;
    }

    setState({ loading: true });
    const loggedIn = await Auth.login({ username: data.username, password: data.password });
    setState({ loading: false });

    if (loggedIn) {
      navigate('/files');
    }
  }

  return (
    <Page>
      <CenteredContainer className='oprx-login-container'>
        <AppTitle />
        <CenteredCard>
          <Card.Body>
            <form className='oprx-login-form' onSubmit={handleSubmit(submit)}>
              <div className={styles.inputGroup + " input-group"}>
                <span className="input-group-text">
                  <img src={PersonFillIcon} alt='' />
                </span>
                <input
                  className="form-control oprx-username-input"
                  placeholder="Username"
                  {...register('username')}
                />
              </div>
              <div className={styles.passwordGroup + " input-group"}>
                <span className="input-group-text">
                  <img className={styles.passwordGroupIcon} src={KeyFillIcon} alt='' />
                </span>
                <input
                  className="form-control oprx-password-input"
                  placeholder="Password"
                  type="password"
                  {...register('password')}
                />
              </div>
              <Button
                text="Login"
                loader={true}
                loading={state.loading}
                disabled={state.disabled}
                icon={true}
                iconClass="bi bi-unlock-fill"
                className="btn-primary oprx-login-btn oprx-centered-btn"
                type="submit"
              />
            </form>
          </Card.Body>
        </CenteredCard>
      </CenteredContainer>
    </Page>
  );
}

export default Login;
