export interface TimerOptions {
  interval?: number;
  cycles?: number | null;
  autoStart?: boolean;
  startWithTick?: boolean;
  tick?: (cycle: number) => void;
}

export class TimerOptions implements TimerOptions {
  interval?: number = 1000;
  cycles?: number | null = null;
  autoStart?: boolean = true;
  startWithTick?: boolean = true;
}
