import { AxiosError, AxiosResponse } from 'axios';
import { AxiosResponseInfo } from './AxiosResponseInfo';
import { AxiosErrorInfo } from './AxiosErrorInfo';
import { BsNotification } from '../utils/BsNotification';
import { ValidationError } from '../validation/ValidationError';

export interface AxiosErrorData {
  message: string;
}

export interface AxiosErrorConfig<E = AxiosErrorData> {
  error?(message: string, response: AxiosResponseInfo<E> | null, error: AxiosErrorInfo<E> | null): void;
}

export interface AxiosValidationErrorData extends AxiosErrorData {
  errors: ValidationError[];
}

export interface AxiosValidationErrorConfig<E = AxiosValidationErrorData> extends AxiosErrorConfig<E> {
  errors?(
    errors: ValidationError[],
    message: string,
    response: AxiosResponseInfo<E> | null,
    error: AxiosErrorInfo<E> | null
  ): void;
}

export interface AxiosSuccessConfig<T = any> {
  success?(json: T, response: AxiosResponseInfo<T> | null): void;
}

export interface AxiosResponseConfig<T = any, E = AxiosErrorData> extends AxiosSuccessConfig<T>, AxiosErrorConfig<E> {}

export interface AxiosValidationResponseConfig<T = any, E = AxiosValidationErrorData> extends AxiosResponseConfig<T, E> {
  errors?(
    errors: ValidationError[],
    message: string,
    response: AxiosResponseInfo<E> | null,
    error: AxiosErrorInfo<E> | null
  ): void;
}

export class AxiosResponseUtils {
  static success<T = any, E = AxiosErrorData>(r: AxiosResponse, config: AxiosResponseConfig<T, E>) {
    const response = new AxiosResponseInfo<T>(r);

    if (response.isOK()) {
      const json = response.json;

      if (config.success) {
        config.success.call(this, json, response);
      }
    } else if (config.error) {
      config.error.call(this, response.statusText, new AxiosResponseInfo<E>(r), null);
    } else {
      BsNotification.error(response.statusText);
    }
  }

  static error<E = AxiosErrorData>(e: AxiosError, config: AxiosErrorConfig<E> = {}) {
    const error = new AxiosErrorInfo<E>(e);
    const message = error.message;
    let response = null;

    if (error.response) {
      response = error.response;
    }

    if (config.error) {
      config.error.call(this, message, response, error);
    } else {
      BsNotification.error(message);
    }
  }

  static jsonValidationErrors(
    message: string, response: AxiosResponseInfo<AxiosValidationErrorData> | null,
    error: AxiosErrorInfo<AxiosValidationErrorData> | null,
    config: AxiosValidationResponseConfig
  ) {
    if (config.errors) {
      let errors = [] as ValidationError[];
      if (response != null) {
        const json = response.json;
        if (json.errors) {
          errors = json.errors;
        }
      }
      config.errors.call(this, errors, message, response, error);
    } else {
      BsNotification.error(message);
    }
  }
}
