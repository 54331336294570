import { type PropsWithChildren } from "react";
import { FormGroup, FormLabel } from "react-bootstrap";

export interface FormGroupWrapperProps extends PropsWithChildren {
  className?: string;
  label?: string;
  inline?: boolean;
}

const FormGroupWrapper = (props: FormGroupWrapperProps) => {
  const containerClass = () => {
    let cls = "oprx-form-group";

    if (props.inline != null && props.inline) {
      cls += " is-inline";
    }

    return cls + " " + (props.className ?? "");
  };

  const renderLabel = () => {
    if (props.label) {
      return <FormLabel>{props.label}</FormLabel>;
    }
  };

  return (
    <FormGroup className={containerClass()}>
      {renderLabel()}
      <div className='oprx-control'>
        {props.children}
      </div>
    </FormGroup>
  );
};

export default FormGroupWrapper;
