export interface IdName {
  id: string;
  name: string;
}

export class IdName implements IdName {
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
