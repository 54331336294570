import { AxiosResponse } from 'axios';
import { LoginInfo } from 'lib/auth/LoginInfo';
import { LoginResponse } from 'lib/auth/LoginResponse';
import { ApiRoutes } from 'lib/utils/ApiRoutes';
import { AxiosRepository } from 'lib/axios/AxiosRepository';
import { axiosRepositoryFactory } from 'lib/axios/AxiosRepositoryFactory';

export class AuthRepository extends AxiosRepository {
  public login(info: LoginInfo): Promise<AxiosResponse<LoginResponse>> {
    return super.post(ApiRoutes.auth, info);
  }
}

export function getAuthRepository () {
  return axiosRepositoryFactory.getInstance('auth', AuthRepository) as AuthRepository;
}
