import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { Auth } from 'lib/auth/Auth';
import { defAppState, useAppState } from 'store/AppStore';

const LogoutButton = () => {
  const navigate = useNavigate();
  const appState = useAppState();

  const handleBtnClick = async () => {
    await Auth.logout();
    appState.set(defAppState);
    navigate('/');
  }

  return (
    <Button
      text="Logout"
      loader={false}
      icon={true}
      iconClass="bi bi-lock-fill"
      className="btn-primary btn-light oprx-logout-btn oprx-centered-btn"
      onClick={handleBtnClick}
    />
  );
}

export default LogoutButton;
