import { AxiosRepository, AxiosRepositoryConfig } from 'lib/axios/AxiosRepository';

export class AxiosRepositoryFactory<T extends AxiosRepository, C extends AxiosRepositoryConfig> {
  private instances = new Map<string, T>();

  private config?: C;

  constructor(config?: C) {
    if (config != null) {
      this.config = config;
    }
  }

  getInstance(name: string, Type: new (config?: C) => T): T {
    const instance = this.instances.get(name);
    if (instance == null) {
      const newInstance: T = new Type(this.config);
      this.instances.set(name, newInstance);
      return newInstance;
    }
    return instance;
  }
}

export const axiosRepositoryFactory = new AxiosRepositoryFactory();
