export class Notice {
  static message(messages: string[] | string): string {
    if (!Array.isArray(messages)) {
      return messages;
    }

    let out = "";

    for (let i = 0; i < messages.length; i += 1) {
      if (i > 0) {
        out += "<br>";
      }
      out += messages[i];
    }

    return out;
  }

  static type(type: string | undefined): string {
    if (type == null) {
      return "info";
    }

    let out = type;

    if (type === "error") {
      out = "danger";
    }

    return out;
  }
}
