import React, { MouseEventHandler } from 'react';
import { ElementState } from 'model/ElementState';

export interface ButtonProps extends ElementState {
  text?: string;
  id?: string;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  loader?: boolean;
  loaderText?: string;
  showLoaderText?: boolean;
  icon?: boolean;
  iconClass?: string;
  onClick?: MouseEventHandler;
}

const Button = (props: ButtonProps) => {
  const displayStyle = (visible: boolean) => {
    if (visible) {
      return {'display': 'inline-block'};
    }
    return {'display': 'none'};
  }

  const renderLoader = () => {
    if (props.loader ?? true) {
      return (
        <>
          <span
            className="spinner-border spinner-border-sm oprx-btn-loader"
            role="status"
            aria-hidden="true"
            style={displayStyle(props.loading ?? false)}
          >
          </span>
          {(props.showLoaderText ?? true) &&
            <span
              className="oprx-btn-loader-text"
              style={displayStyle(props.loading ?? false)}
            >
              {props.loaderText ?? 'Processing...'}
            </span>
          }
        </>
      )
    } else {
      return <></>
    }
  }

  const renderIcon = () => {
    if (props.icon ?? false) {
      return (
        <>
          <span className="oprx-btn-icon" style={displayStyle(!props.loading ?? true)}>
            { props.iconClass  && <i className={props.iconClass}></i>}
          </span>
        </>
      )
    } else {
      return (<></>);
    }
  }

  return (
    <button
      id={props.id ?? ''}
      className={'btn ' + (props.className ?? 'btn-primary')}
      type={props.type ?? 'button'}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {renderLoader()}
      {renderIcon()}
      <span className="oprx-btn-text" style={displayStyle(!props.loading ?? true)}>{props.text ?? ''}</span>
    </button>
  );
}

export default Button;
