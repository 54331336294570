import { Notice } from "./Notice";

export interface BsNotificationConfig {
  message: string | string[];
  duration?: number | null;
  container: Element | string | null;
  type: string;
}

export type BsNotificationParams = BsNotificationConfig | string | string[];

export class BsNotification {
  static open(params: BsNotificationParams): void {
    let config = this.defConfig();
    let message: string = "";

    if (params != null) {
      if (Array.isArray(params) || typeof params === "string") {
        message = Notice.message(params);
      } else {
        config = { ...config, ...params };
        config.type = Notice.type(config.type);
        message = Notice.message(config.message);
      }
    }

    let container: Element | null = null;

    if (config.container == null) {
      container = document.querySelector("#oprx-bs-alert-container");
    }

    if (typeof config.container === "string") {
      container = document.querySelector(config.container);
    }

    if (container == null) {
      throw new Error("BsNotification: container is null");
    }

    container.innerHTML = "";
    container.innerHTML = `
      <div class="alert alert-${config.type} alert-dismissible fade show" role="alert">
        <span>${message}</span>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `;

    const btnClose = container.querySelector(".btn-close");
    let closeTimeout: ReturnType<typeof setTimeout> | null = null;

    const closeHandler = function () {
      if (btnClose != null) {
        btnClose.removeEventListener("click", closeHandler, false);
      }

      if (container != null) {
        container.innerHTML = "";
      }

      if (closeTimeout != null) {
        clearTimeout(closeTimeout);
      }
    };

    if (btnClose != null) {
      btnClose.addEventListener("click", closeHandler);
    }

    if (config.duration != null) {
      closeTimeout = setTimeout(function () {
        closeHandler();
      }, config.duration);
    }
  }

  static success(params: BsNotificationParams): void {
    return this.openType("success", params);
  }

  static error(params: BsNotificationParams): void {
    return this.openType("danger", params);
  }

  static warning(params: BsNotificationParams): void {
    return this.openType("warning", params);
  }

  static info(params: BsNotificationParams): void {
    return this.openType("info", params);
  }

  private static openType(type: string, params: BsNotificationParams): void {
    let config = {} as BsNotificationConfig;

    if (Array.isArray(params) || typeof params === "string") {
      config.message = params;
    } else {
      config = params;
    }

    config.type = type;
    this.open(config);
  }

  private static defConfig(): BsNotificationConfig {
    return {
      message: "",
      duration: 7000,
      container: null,
      type: "info",
    } as BsNotificationConfig;
  }
}
