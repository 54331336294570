import { CsvRowError } from 'model/CsvRowError';

export enum UploadProgressStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export interface UploadProgress {
  status: UploadProgressStatus;
  message?: string | null;
  errors?: CsvRowError[];
}

export class UploadProgress {
  constructor(status: UploadProgressStatus) {
    this.status = status;
  }
}
