import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { AxiosInstanceBuilder } from "./AxiosInstanceBuilder";
import { AxiosRequestUtils } from "./AxiosRequestUtils";

export interface AxiosRepository {
  get(url: string, data?: Record<string, string | number>, config?: AxiosRequestConfig): Promise<AxiosResponse>
  getBlob(url: string, data?: Record<string, string | number>, config?: AxiosRequestConfig): Promise<AxiosResponse>
  post(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse>
  postForm(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse>
  put(url: string, data: unknown): Promise<AxiosResponse>
  delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse>
}

export interface AxiosRepositoryConfig extends AxiosRequestConfig {}

export class AxiosRepository implements AxiosRepository {
  protected baseURL: string;
  protected axios: AxiosInstance;

  constructor(config?: AxiosRepositoryConfig) {
    this.baseURL = config?.baseURL != null ? config.baseURL : process.env.REACT_APP_BASE_URL ?? '';

    let axiosConfig = {
      baseURL: this.baseURL
    };

    if (config != null) {
      axiosConfig = { ...axiosConfig, ...config };
    }

    this.axios = new AxiosInstanceBuilder().withConfig(axiosConfig).build();
  }

  get(
    url: string,
    data?: Record<string, string | number>,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axios.get(AxiosRequestUtils.encodeUrl(url, data), {
      ...{ headers: AxiosRequestUtils.jsonHeaders() },
      ...config,
    });
  }

  getBlob(
    url: string,
    data?: Record<string, string | number>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return this.axios.get(AxiosRequestUtils.encodeUrl(url, data), {
      ...{ headers: AxiosRequestUtils.jsonHeaders(), responseType: 'blob' },
      ...config,
    });
  }

  post(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.axios.post(url, data, { ...{ headers: AxiosRequestUtils.jsonHeaders() }, ...config });
  }

  postForm(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.axios.post(url, data, { ...{ headers: AxiosRequestUtils.jsonFormDataHeaders() }, ...config });
  }

  put(url: string, data: unknown): Promise<AxiosResponse> {
    return this.axios.put(url, data, { headers: AxiosRequestUtils.jsonHeaders() });
  }

  delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.axios.delete(url, config);
  }
}
