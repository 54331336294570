import { Nav, Navbar } from 'react-bootstrap';
import LogoutButton from 'components/LogoutButton';
import styles from 'assets/styles/TopNav.module.css';
import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

const TopNav = () => {
  const navigate = useNavigate();

  const onNavLinkClick = (event: FormEvent, to: string) => {
    event.preventDefault();
    navigate(to);
  }

  return (
    <div className={styles.bar}>
      <Navbar className="justify-content-between">
        <Nav className="me-auto">
          <Nav.Link
            className={'oprx-nav-link-files ' + styles.navlink}
            onClick={(event) => onNavLinkClick(event, '/files')}
          >
            Files
          </Nav.Link>
          <Nav.Link
            className={'oprx-nav-link-upload ' + styles.navlink}
            onClick={(event) => onNavLinkClick(event, '/upload')}
          >
            Upload
          </Nav.Link>
        </Nav>
        <LogoutButton/>
      </Navbar>
    </div>
  );
}

export default TopNav;
