import { User } from '../../model/User';

export interface UserInfo {
  user: User | null;
  token: string | null;
}

export class UserInfo implements UserInfo {
  user: User | null = null;
  token: string | null = null;

  constructor(user?: User | null) {
    if (user != null) {
      this.user = user;
    }
  }
}
