export class ApiRoutes {
  static auth = 'auth';
  static upload = (transactionType: string) => {
    return `file_upload?transactionType=${transactionType}`;
  };
  static progress = 'progress';
  static download = 'file_download';
  static files = 'files';

}
