import { Props } from 'model/Props';
import { Card } from 'react-bootstrap';

const CenteredCard = ( props: Props ) => {
  return (
    <Card className={'oprx-centered-card ' + (props.className ?? '')}>
      { props.children }
    </Card>
  );
}

export default CenteredCard;
