import React from 'react';
import './assets/styles/base.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './views/Login';
import Upload from './views/Upload';
import ProtectedRouteElement from './components/ProtectedRouteElement';
import Files from './views/Files';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/files" element={<ProtectedRouteElement element={<Files />} />} />
        <Route path="/upload" element={<ProtectedRouteElement element={<Upload />} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
