import { AxiosInstance, AxiosRequestConfig, default as axios } from 'axios';
import { UserInfoStorage } from '../auth/UserInfoStorage';

export class AxiosInstanceBuilder {
  private instance?: AxiosInstance;
  private config: AxiosRequestConfig;

  constructor() {
    this.config = {} as AxiosRequestConfig;
  }

  withConfig(config: AxiosRequestConfig): AxiosInstanceBuilder {
    this.config = config;
    return this;
  }

  build(): AxiosInstance {
    this.config.withCredentials = true;
    this.instance = axios.create(this.config);
    this.instance.interceptors.request.use(
      config => {
        const token = UserInfoStorage.getUserInfo().token;
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    return this.instance;
  }
}
