import { AxiosResponse } from 'axios';
import { UploadResult } from 'model/UploadResult';
import { UploadProgress } from 'model/UploadProgress';
import { ApiRoutes } from 'lib/utils/ApiRoutes';
import { AxiosRepository } from 'lib/axios/AxiosRepository';
import { axiosRepositoryFactory } from 'lib/axios/AxiosRepositoryFactory';
import { FileEntry } from 'model/FileEntry';
import { DataTableResponse } from 'model/datatable/DataTableResponse';

export class FileRepository extends AxiosRepository {
  public upload(transactionType: string, data: FormData): Promise<AxiosResponse<UploadResult>> {
    return super.postForm(ApiRoutes.upload(transactionType), data);
  }

  public progress(fileId: string, version: number): Promise<AxiosResponse<UploadProgress>> {
    return super.get(ApiRoutes.progress, { identifier: fileId, version: version });
  }

  public download(fileId: string): Promise<AxiosResponse<any>> {
    return super.getBlob(ApiRoutes.download, { identifier: fileId });
  }

  public files(): Promise<AxiosResponse<DataTableResponse<FileEntry>>> {
    return super.post(ApiRoutes.files);
  }
}

export function getFileRepository () {
  return axiosRepositoryFactory.getInstance('file', FileRepository) as FileRepository;
}
