import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AxiosResponseInfo } from './AxiosResponseInfo';

export class AxiosErrorInfo<T> {
  protected _error: AxiosError;

  constructor(error: AxiosError) {
    this._error = error;
  }

  get error(): AxiosError {
    return this._error;
  }

  get config(): InternalAxiosRequestConfig<any> | undefined {
    return this._error.config;
  }

  get code(): string | undefined {
    return this._error.code;
  }

  get message(): string {
    const response = this.response;
    let data;

    if (response) {
      data = response.data;
    }

    if (data && data.message) {
      return data.message;
    }

    if (this._error.message) {
      return this._error.message;
    }

    return '';
  }

  get request(): any {
    return this._error.request;
  }

  get response(): AxiosResponseInfo<T> | undefined {
    let response;
    if (this._error.response) {
      response = new AxiosResponseInfo<T>(this._error.response);
    }
    return response;
  }

  get isAxiosError(): boolean {
    return this._error.isAxiosError;
  }

  get toJSON(): () => Record<string, unknown> {
    return this._error.toJSON as () => Record<string, unknown>;
  }
}
