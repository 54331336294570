import { Modal, ModalProps } from 'react-bootstrap';
import { CsvRowError } from 'model/CsvRowError';
import { nanoid } from 'nanoid'

export interface UploadErrorModalProps extends ModalProps {
  title?: string;
  message?: string;
  errors?: CsvRowError[];
}

const UploadErrorModal = (props: UploadErrorModalProps) => {
  const renderErrorHeader = (error: CsvRowError) => {
    if (error.header != null) {
      return <div className="oprx-row-error-header"><span className="fw-bold">Header:</span> {error.header}</div>
    }
    return <></>
  }

  const renderErrors = (errors: CsvRowError[]) => {
    return errors.map((error, index) =>
      <div key={nanoid()} className="oprx-row-error-content mb-3">
        {renderErrorHeader(error)}
        <div className="oprx-row-error-message"><span className="fw-bold">Message:</span> {error.message ?? ''}</div>
      </div>
    )
  }

  const renderBody = () => {
    const message = props.message;
    const errors = props.errors;
    const errorsMap: Record<string, CsvRowError[]> = {};
    let content = <></>;

    if (message != null) {
      content = <>{message}</>;
    }

    if (errors != null) {
      errors.forEach(error => {
        const rowIndex = error.index;

        if (errorsMap[rowIndex] == null) {
          errorsMap[rowIndex] = [];
        }

        errorsMap[rowIndex].push(error);
      });

      content =
        <>
          {
            Object.keys(errorsMap).map((rowIndex, index) => {
              return (
                <div key={nanoid()} className="mb-4 oprx-row-error-container">
                  <span className="fw-bold text-danger oprx-row-error-index">Row {rowIndex}</span>
                  <hr className="mt-0"/>
                  {renderErrors(errorsMap[rowIndex])}
                </div>
              )
            })
          }
        </>;
    }

    return (
      <>
        {content}
      </>
    )
  }

  return (
    <Modal {...props} size="lg" data-testid="oprx-upload-error-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          <span className="text-danger fw-bold">{props.title ?? 'Errors'}</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {renderBody()}
      </Modal.Body>

    </Modal>
  );
}

export default UploadErrorModal;
