import { Props } from 'model/Props';

const Page = ( props: Props ) => {
  return (
    <div className={'oprx-page-container ' + (props.className ?? '')}>
      { props.children }
    </div>
  );
}

export default Page;
