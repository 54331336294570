import React, { useEffect, useState } from 'react';
import TopNav from 'components/TopNav';
import Page from 'layouts/Page';
import AppTitle from 'components/AppTitle';
import { Card, Container, Table } from 'react-bootstrap';
import CenteredCard from 'components/CenteredCard';
import styles from 'assets/styles/Files.module.css';
import { fetchFiles } from 'services/files';
import { FileEntry } from 'model/FileEntry';
import { DataTableResponse } from 'model/datatable/DataTableResponse';
import { nanoid } from 'nanoid';
import Button from 'components/Button';
import { AxiosResponseUtils } from 'lib/axios/AxiosResponseUtils';
import { AxiosError } from 'axios';
import { ElementState } from 'model/ElementState';
import { format } from 'date-fns';
import { CsvProcessStatus } from 'model/CsvProcessStatus';
import { FileUtils } from 'lib/utils/FileUtils';

const defBtnState: Record<string, ElementState> = {};

const Files = () => {
  const [dataTableResponse, setDataTableResponse] =
    useState<DataTableResponse<FileEntry>>(new DataTableResponse<FileEntry>([], 0));
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [btnState, setBtnState] = useState<Record<string, ElementState>>(defBtnState);

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);

      const response = await fetchFiles();
      const newBtnState: Record<string, ElementState> = {};

      response.data.forEach(file => {
        newBtnState[file.identifier] = { loading: false, disabled: false };
      });

      setBtnState(newBtnState);
      setTableLoading(false);
      setDataTableResponse(response);
    }
    fetchData();
  }, []);

  const setBtnLoading = (identifier: string, loading: boolean) => {
    const newBtnState: Record<string, ElementState> = {};
    let disabled = false;

    if (loading) {
      disabled = true;
    }

    dataTableResponse.data.forEach(file => {
      if (file.identifier === identifier) {
        newBtnState[file.identifier] = { loading: loading, disabled: disabled }
      } else {
        newBtnState[file.identifier] = { loading: false, disabled: disabled }
      }
    });

    setBtnState(newBtnState);
  }

  const renderLoaderRow = () => {
    if (tableLoading) {
      return(
        <tr>
          <td colSpan={4} className='text-center'>
            <span>Loading </span>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            >
            </span>
          </td>
        </tr>
      )
    }
    return <></>
  }

  const renderNoDataRow = () => {
    if (dataTableResponse.data.length < 1 && !tableLoading) {
      return(
        <tr>
          <td colSpan={4} className='text-center'>No data available</td>
        </tr>
      )
    }
    return <></>
  }

  const onDownloadBtnClick = async (file: FileEntry) => {
    const identifier = file.identifier;

    setBtnLoading(identifier, true);

    try {
      await FileUtils.downloadFile(identifier, getFileName(file, 'csv'));
    } catch (error) {
      AxiosResponseUtils.error(error as AxiosError);
    }

    setBtnLoading(identifier, false);
  }

  const getFileName = (file: FileEntry, suffix?: string): string => {
    return file.transactionType.toString().toLowerCase() + '_' +
      format(new Date(file.date), 'MM-dd-yyyy_hh-mm-ss-a') + '_' +
      file.username +
      (suffix != null ? '.' + suffix : '');
  }

  const getCellClassName = (file: FileEntry): string => {
    const status = file.status;
    let className = 'oprx-success';

    if (status === CsvProcessStatus.ERRORS) {
      className = 'oprx-warning';
    }

    if (status === CsvProcessStatus.FAILED) {
      className = 'oprx-error';
    }

    return className + ' ' + styles.cell;
  }

  return (
    <Page>
      <TopNav />
      <Container className='oprx-files-container'>
        <AppTitle title='Files'/>
        <CenteredCard className={styles.card}>
          <Card.Body>
            <Table className='oprx-files-table' data-testid='oprx-files-table' bordered hover responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Size</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {renderLoaderRow()}
                {renderNoDataRow()}
                {
                  dataTableResponse.data.length > 0 && !tableLoading && dataTableResponse.data.map(file => {
                    return (
                      <tr key={nanoid()}>
                        <td className={getCellClassName(file)}>{getFileName(file)}</td>
                        <td className={getCellClassName(file)}>{format(new Date(file.date), 'MM/dd/yyyy hh:mm:ss a')}</td>
                        <td className={getCellClassName(file)}>{file.size}</td>
                        <td className={getCellClassName(file) + ' text-center'}>
                          <Button
                            loader={true}
                            showLoaderText={false}
                            loading={(btnState[file.identifier].loading) ?? false}
                            disabled={(btnState[file.identifier].disabled) ?? false}
                            icon={true}
                            iconClass={'bi bi-cloud-download-fill ' + styles.btnIcon}
                            className={'btn-primary btn-sm oprx-btn-download ' + styles.btn}
                            type="submit"
                            onClick={() => { onDownloadBtnClick(file)} }
                          />
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Card.Body>
        </CenteredCard>
      </Container>
    </Page>
  )
}

export default Files;
