import React, { ChangeEvent, ChangeEventHandler, FormEvent, useState } from "react";
import Page from "layouts/Page";
import { FieldValues, useForm } from "react-hook-form";
import AppTitle from "components/AppTitle";
import { getFileRepository } from "repositories/FileRepository";
import { AxiosResponseUtils } from "lib/axios/AxiosResponseUtils";
import { AxiosError } from "axios";
import Button from "components/Button";
import { UploadProgress, UploadProgressStatus } from "model/UploadProgress";
import { Timer } from "lib/timer/Timer";
import { BsNotification } from "lib/utils/BsNotification";
import { Card, Container, FormSelect } from 'react-bootstrap';
import TopNav from 'components/TopNav';
import { useAppState } from 'store/AppStore';
import styles from 'assets/styles/Upload.module.css';
import CenteredCard from 'components/CenteredCard';
import FormGroupWrapper from 'components/FormGroupWrapper';
import { ElementState } from 'model/ElementState';
import { TransactionType } from 'model/TransactionType';
import { IdName } from 'model/IdName';
import UploadErrorModal, { UploadErrorModalProps } from 'components/UploadErrorModal';
import { FileUtils } from 'lib/utils/FileUtils';

const fileRepository = getFileRepository();

const defButtonState: ElementState = {
  disabled: true,
  loading: false,
};

const defErrorModalState: UploadErrorModalProps = {
  visible: false
}

const Upload = () => {
  const { register, handleSubmit } = useForm();
  const appState = useAppState();
  const [buttonState, setButtonState] = useState<ElementState>(defButtonState);
  const [errorModalState, setErrorModalState] = useState<UploadErrorModalProps>(defErrorModalState);
  const transactionTypes = appState.transactionTypes.get() as TransactionType[];
  const selectedTransactionType = appState.selectedTransactionType.get() as TransactionType | null;

  let timer: Timer | null = null;
  let fileName: string | null = null;

  const submit = async (data: FieldValues) => {
    if (buttonState.loading) {
      return;
    }

    const fileList = data.file as FileList;

    if (fileList.length > 0) {
      setButtonState({ loading: true });
      const file = fileList[0];
      const fileName = file.name;
      const formData: FormData = new FormData();
      formData.append('file', file, fileName);
      upload(formData);
    }
  };

  const upload = async (formData: FormData) => {
    try {
      const response = await fileRepository.upload(
        selectedTransactionType?.id ?? '', formData
      );
      fileName = response.data.name ?? null;
      progress(response.data.identifier ?? "");
    } catch (error) {
      AxiosResponseUtils.error(error as AxiosError);
      setButtonState({ loading: false });
    }
  };

  const progress = (fileId: string) => {
    let version = 0;

    timer = new Timer({
      interval: parseInt(process.env.REACT_APP_PROGRESS_INTERVAL ?? "3000"),
      startWithTick: true,
      tick: async () => {
        if (timer) {
          timer.pause();
        }

        let progress = new UploadProgress(UploadProgressStatus.IN_PROGRESS);

        try {
          const response = await fileRepository.progress(fileId, version);
          progress = response.data;
          version++;
        } catch (error) {
          AxiosResponseUtils.error(error as AxiosError);
          setButtonState({ loading: false });

          if (timer) {
            timer.stop();
            return;
          }
        }

        if (progress.status === UploadProgressStatus.DONE) {
          handleDoneProgress(timer, fileId);
          return;
        }

        if (progress.status === UploadProgressStatus.ERROR) {
          handleErrorProgress(timer, progress);
          return;
        }

        if (timer) {
          timer.resume();
        }
      },
    });
  };

  const handleDoneProgress = (timer: Timer | null, fileId: string) => {
    if (timer) {
      timer.stop();
    }

    download(fileId);
  }

  const handleErrorProgress = (timer: Timer | null, progress: UploadProgress) => {
    setButtonState({ loading: false });

    if (timer) {
      timer.stop();
    }

    const message = progress.message;
    const errors = progress.errors;

    if (message != null && message.length > 0) {
      setErrorModalState({ visible: true, message: message });
      return;
    }

    if (errors != null && errors.length > 0) {
      setErrorModalState({ visible: true, errors: errors });
    }
  }

  const download = async (fileId: string) => {
    if (fileName == null) {
      BsNotification.error("Error downloading file");
      return;
    }

    try {
      await FileUtils.downloadFile(fileId, fileName);
    } catch (error) {
      AxiosResponseUtils.error(error as AxiosError);
    }

    fileName = null;
    setButtonState({ loading: false });
  };

  const onFileInput = (event: FormEvent) => {
    let files = (event.target as HTMLInputElement).files;

    if (files != null) {
      let size = files.length;

      if (size > 0) {
        setButtonState({ disabled: false });
      } else {
        setButtonState({ disabled: true });
      }
    } else {
      setButtonState({ disabled: true });
    }
  };

  const isButtonDisabled = () => {
    return buttonState.disabled || selectedTransactionType == null;
  }

  const renderSelectLoading = () => {
    return (
      <>
        <span>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          >
          </span>
        </span>
        &nbsp;
        <span>
          Loading...
        </span>
      </>
    );
  }

  const renderIdNameSelect = (
    entries: IdName[], selectedEntry: IdName | null, className: string, onChange?: ChangeEventHandler<HTMLSelectElement>
  ) => {
    const renderEmptyOption = () => {
      if ((selectedEntry?.id) == null) {
        return (
          <option></option>
        )
      }
    }

    if (entries.length > 0) {
      return (
        <FormSelect className={className} value={(selectedEntry?.id) ?? ''} onChange={onChange}>
          {renderEmptyOption()}
          {
            entries.map((entry) => (
              <option key={entry.id} value={entry.id}>
                {entry.name}
              </option>
            ))
          }
        </FormSelect>
      );
    }

    return renderSelectLoading();
  }

  const onIdNameChange = (event: ChangeEvent<HTMLSelectElement>, entries: IdName[], stateKey: string) => {
    const id = event.target.value;

    if (id.length < 1) {
      return;
    }

    const filteredEntries = entries.filter((entry) => entry.id + '' === id);

    if (filteredEntries.length === 1) {
      appState.merge({ [stateKey]: filteredEntries[0] });
    }
  }

  const renderTransactionTypesSelect = () => {
    return renderIdNameSelect(transactionTypes, selectedTransactionType, 'oprx-transaction-type-select', (event) => {
      onIdNameChange(event, transactionTypes, 'selectedTransactionType');
    });
  }

  return (
    <Page>
      <TopNav />
      <UploadErrorModal
        show={errorModalState.visible}
        message={errorModalState.message}
        errors={errorModalState.errors}
        onHide={() => { setErrorModalState({ visible: false }) }}
      />
      <Container className='oprx-upload-container'>
        <AppTitle title='Upload'/>
        <CenteredCard>
          <Card.Body>
            <FormGroupWrapper label="Transaction Type">
              {renderTransactionTypesSelect()}
            </FormGroupWrapper>
            <form onSubmit={handleSubmit(submit)}>
              <FormGroupWrapper label="File">
                <div className={styles.uploadGroup + ' input-group'}>
                  <input
                    className="form-control oprx-file-input"
                    type="file"
                    onInput={(event) => onFileInput(event)}
                    {...register('file')}
                  />
                </div>
              </FormGroupWrapper>
              <Button
                text="Upload"
                loader={true}
                loading={buttonState.loading}
                disabled={isButtonDisabled()}
                icon={true}
                iconClass="bi bi-cloud-arrow-up-fill"
                className="btn-primary oprx-upload-btn oprx-centered-btn"
                type="submit"
              />
            </form>
            <br/>
            <div className="text-center">
              <a
                href="https://optimizerx.atlassian.net/wiki/spaces/EO/pages/2604761089/BTT+File+Specification"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </div>
          </Card.Body>
        </CenteredCard>
      </Container>
    </Page>
  );
};

export default Upload;
