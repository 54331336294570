import { UserInfo } from './UserInfo';
import { SessionStorage } from '../storage/SessionStorage';

export class UserInfoStorage {
  static getUserInfo(): UserInfo {
    const storage = new SessionStorage();
    const storedInfo = storage.getItem('userInfo');

    if (storedInfo != null) {
      return storedInfo as UserInfo;
    }

    return new UserInfo();
  }

  static setUserInfo(userInfo: UserInfo): void {
    const storage = new SessionStorage();
    storage.setItem('userInfo', userInfo);
  }

  static clearUserInfo(): void {
    this.setUserInfo(new UserInfo());
  }
}
