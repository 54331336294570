import { getFileRepository } from 'repositories/FileRepository';

const fileRepository = getFileRepository();

export class FileUtils {
  public static downloadContent = (content: Blob, fileName: string): void => {
    const url = URL.createObjectURL(content);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  public static downloadFile = async (fileId: string, fileName: string): Promise<void> => {
    const response = await fileRepository.download(fileId);
    const contentType = response.headers["content-type"];
    const content = new Blob([response.data], { type: contentType });
    this.downloadContent(content, fileName);
  }
}
