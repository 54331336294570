export interface DataTableResponse<T> {
    data: T[],
    total: number;
    filtered: number;
}

export class DataTableResponse<T> implements DataTableResponse<T> {
    constructor(data: T[], total: number, filtered?: number) {
        this.data = data;
        this.total = total;
        this.filtered = filtered != null ? filtered : total;
    }
}
