import { Props } from 'model/Props';
import { Container } from 'react-bootstrap';

const CenteredContainer = ( props: Props ) => {
  return (
    <Container className={'oprx-vertical-centered ' + (props.className ?? '')}>
      { props.children }
    </Container>
  );
}

export default CenteredContainer;
