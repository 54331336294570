import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class AxiosResponseInfo<T> {
  protected _response: AxiosResponse;

  constructor(response: AxiosResponse) {
    this._response = response;
  }

  get response(): AxiosResponse {
    return this._response;
  }

  get data(): any {
    return this._response.data;
  }

  get status(): number {
    return this._response.status;
  }

  get statusText(): string {
    return this._response.statusText;
  }

  get headers(): any {
    return this._response.headers;
  }

  get config(): AxiosRequestConfig {
    return this._response.config;
  }

  get request(): any {
    return this._response.request;
  }

  get json(): T {
    if (typeof this._response.data === 'string') {
      return JSON.parse(this._response.data) as T;
    }
    return this._response.data as T;
  }

  isSuccess(): boolean {
    return this._response.status > 199 && this._response.status < 300;
  }

  isOK(): boolean {
    return this._response.status === 200;
  }

  isClientError(): boolean {
    return this._response.status > 399 && this._response.status < 500;
  }

  isUnauthorized(): boolean {
    return this._response.status === 401;
  }

  isNotFound(): boolean {
    return this._response.status === 404;
  }

  isServerError(): boolean {
    return this._response.status > 499 && this._response.status < 600;
  }

  isInternalServerError(): boolean {
    return this._response.status === 500;
  }
}
