import { IdName } from 'model/IdName';

export enum TransactionTypeId {
  RXID = 'RXID',
  RXID_PLUS = 'RXID_PLUS',
  FAS = 'FAS'
}

export enum TransactionTypeName {
  RXID = 'RXID',
  RXID_PLUS = 'RXID+',
  FAS = 'FAS'
}

export interface TransactionType extends IdName {}

export class TransactionType extends IdName {}
