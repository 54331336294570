export interface WebStorage<T> {
  length: number;
  getItem(key: string): T | null;
  setItem(key: string, item: T): void;
  setItemRaw(key: string, item: any): void;
  removeItem(key: string): void;
  clear(): void;
}

export abstract class AbstractWebStorage<T> implements WebStorage<T> {
  protected storage!: Storage;

  constructor(storage?: Storage) {
    if (storage != null) {
      this.storage = storage;
    }
  }

  get length(): number {
    return this.storage.length;
  }

  getItem(key: string): T | null {
    const item = this.storage.getItem(key);
    if (item != null) {
      return JSON.parse(item) as T;
    }
    return null;
  }

  setItem(key: string, item: T | null): void {
    this.storage.setItem(key, JSON.stringify(item));
  }

  setItemRaw(key: string, item: any): void {
    this.storage.setItem(key, item);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
